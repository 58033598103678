.sideDrawer {
    max-width: 210px;
    border-right: 1px solid #CCCCCC;
    position: fixed;
    height: 100%
}

.navItems_wrapper {
    margin-top: 2.5rem;
    margin-left: 1.825rem;
}

.navItems_label {
    font-family: 'MyriadPro';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #444444;
    padding: 0.625rem 1.5rem 0.625rem 0.625rem;
    cursor: pointer;
    margin-bottom: 0.25rem;
}

.isActive{
    background: #EEEEEE;
    border-radius: 8px 0px 0px 8px;
}