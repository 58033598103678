.support_wrapper {
    padding: 2.5rem;
    margin-left: 210px;
}

.support_link_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.support_link_container div:first-child {
    max-width: 750px;
    font-family: 'MyriadPro';
}

.support_search_wrapper {
    font-family: 'MyriadPro';
    margin-top: -8px;
    margin-left: 160px;
    background-image: url('../../assets/Search-Bg.svg');
    background-repeat: no-repeat;
    background-size: cover;
    height: 276px;
    position: relative;
}

.support_search_wrapper_input {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)
}

.submit_request_link a {
    text-decoration: none;
    color: #0000FF;
    font-family: 'MyriadPro';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 16px;
}

.submit_request_link a:hover {
    text-decoration: underline;
}
