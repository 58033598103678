.navMenu_wrapper{
    position: sticky;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.25rem;
    height: 60px;
    background:  #0c69ff;
    font-family: 'Arial';
    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
    font-style: normal;
    color: #FEFEFE;
    top: 0;
    width: 100%;
    overflow: hidden;
}

.left_container {
    display: flex;
    flex-wrap: wrap;
}

.logo {
    padding: 10px;
}

.header-title {
    margin-top: 5px;
    font-family: 'Avenir';
    font-style: normal;
}

.right_container{
    display: flex;
    justify-content: flex-end;
    margin-right: 3rem;

    font-family: 'Avenir';
    font-style: normal;
    font-weight: 200;
    font-size: 16px;
    line-height: 19px;
    text-align: center;

    
}

.userName{
    margin-right: 10px;
    margin-top: 9px;
}

.menu_icon{
    width: 2rem;
}

@media (min-width: 360px){
    .menu_icon{
        display: none;
    }
}