.subscription_wrapper {
    padding: 2.5rem;
    margin-left: 210px;
    display: flex;
    gap: 2.5rem;
    font-family: 'MyriadPro';
}

.details_wrapper {
    max-height: 298px;
}

.btn_wrapper {
    font-family: 'MyriadPro';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    background: #0c69ff;
    color: #FFFFFF;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    border: none;
    height: 22px;
    cursor: pointer;
}

.subscription_display {
    display: flex;
    flex-wrap: wrap;
}

.contact_wrapper{
    margin: 15px;
}

.subTitle {
    font-family: 'MyriadPro';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
    color: #000;
    border-bottom: 1px solid #CCCCCC;
    padding: 0.25rem 1.25rem;
    display: flex;
}


.subscription_content_wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.subscription_content_title {
    font-family: 'MyriadPro';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    display: flex;
    color: #0c69ff;
    min-width: 150px;
}

.subscription_content_value {
    font-family: 'MyriadPro';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: #444444;
}

.logo_wrapper {
    display: flex;
    align-items: center;
}

.logo_wrapper>img {
    margin-right: 1rem;
}

.modal_description p {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
}

.modal_description .modal_footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 4rem;
    
}

.loadingErrorMessage{
    box-sizing: border-box;
    margin-top: 45px;

    left: 500px;
    top: 310px;

    background: #a0eeb1;
    border: 1px solid rgb(53, 156, 40);
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
}

.loadingErrorMessageText {
    font-family: 'MyriadPro';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    margin: 20px;

    color: #000000;
}

.icon_image {
    width: 24px;
    height: 24px;
    margin-right: 5px;
}

.noSubscriptionsMessage{
    box-sizing: border-box;
    margin-top: 45px;

    left: 500px;
    top: 310px;

    background: #FFEEEE;
    border: 1px solid #FF0000;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
}

.noSubscriptionsMessageText {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    margin: 20px;

    color: #000000;
}