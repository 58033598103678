.organization_wrapper {
    padding: 2.5rem;
    margin-left: 210px;
    display: flex;
    gap: 2.5rem;
    font-family: 'MyriadPro';
}


.information_wrapper{
    display: flex;
    gap: 2.5rem;
    padding: 2.5rem;
    margin-left: 210px;
}

.wrapper {
    border: 1px solid #CCCCCC;
    box-sizing: border-box;
    border-radius: 8px;
    width: 550px;
}


.orgTitle {
    font-family: 'MyriadPro';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
    color: #000;
    border-bottom: 1px solid #CCCCCC;
    padding: 0.25rem 1.25rem;
}

.details_wrapper {
    padding: 1.25rem;
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
}

.organization_content_wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.organization_content_title {
    font-family: 'MyriadPro';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    display: flex;
    color: #0c69ff;
    min-width: 150px;
}

.organization_content_value {
    font-family: 'MyriadPro';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: #444444;
}