.container{
    display: flex;
    justify-content: center;
    
    flex-flow: wrap;
    overflow: hidden;
}

.line-break {
    width: 100%;
  }

.googleLogin{
    margin-top: 130px;
    box-sizing: border-box;
    background: #4285F4;
    border: 1px solid #4285F4;
    z-index: 3;
}

.domainError{
    box-sizing: border-box;
    margin-top: 45px;

    left: 500px;
    top: 310px;

    background: #FFEEEE;
    border: 1px solid #FF0000;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
}

.domainErrorText {
    font-family: 'MyriadPro';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    margin: 20px;

    color: #000000;
}

.loadingErrorMessage{
    box-sizing: border-box;
    margin-top: 45px;

    left: 500px;
    top: 310px;

    background: #006098;
    border: 1px solid rgb(255, 255, 255);
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
}

.loadingErrorMessageText {
    font-family: 'MyriadPro';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    margin: 20px;

    color: #ffffff;
}

.bottomImage{
    position: fixed;
    /* bottom: 60px; */
    width: 100%;
    height: 100%;
    z-index: -1;
    background:  #0c69ff;
}
