.modal_wrapper {
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 1rem 1.5rem;
    border-radius: 8px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    max-width: 450px
}

.modal_title {
    font-family: 'MyriadPro';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    max-width: 350px;
}

.modal_closeBtn {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer
}

.modal_body{
    font-family: 'MyriadPro';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    margin-top:1rem;
}