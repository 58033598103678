.footer_wrapper {
    box-sizing: border-box;
    position: fixed;
    bottom: 0px;
    display: flex;
    background: #000000;
    width: 100%;
    height: 60px;
    padding: 1.25rem;
    align-items: center;
    justify-content: space-between;
}

.footer_label {
    background: none;
    border: none;
    box-shadow: none;
    font-family: 'MyriadPro';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    padding: 0rem;
    padding-right: 1rem;
    /* line-height: 14px; */
    color: rgb(255, 255, 255);
    text-decoration: none;
}

ul {
    display: flex;
    list-style-type: none;
}

.sub-menu {
    padding-bottom: 0px;
    margin-bottom: 0px;

}

li:nth-child(1) {
    margin-right: 1.25rem;
}