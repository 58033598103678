
.profile_header{
    font-family: 'MyriadPro';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 14px;
    background: #CCCCCC;
    padding: 1rem;
    min-width: 190px;
    text-align: center;
}

.profile_image{
   display: flex;
   justify-content: center;
   align-items: center;
   padding: 0.75rem 0;
}
.navMenuImage{
    box-sizing: border-box;
    border:1px #4285F4;
    border-radius: 500px;

    width: 34px;
    height: 34px;
}
.profile_image img {
    width: 50px;
    box-sizing: border-box;

    width: 50px;
    height: 50px;

    border:1px solid black;
    border-radius: 500px;
}

.profile_footer {
   text-align: center;
}

.profile_footer > p {
    font-family: 'MyriadPro';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    margin-top: 0px;
}

.profile_footer > p:nth-child(2){
    padding-top: 0.25rem;
    color: #0000FF;
    cursor: pointer;
}
